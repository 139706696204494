import { useTranslation } from 'react-i18next';

export const AboutUs = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className="flex justify-center">
      <p className="text-[#687888] font-bold text-center max-w-[1000px] py-16 px-5">
        {t('aboutUs')}
      </p>
    </section>
  );
};
