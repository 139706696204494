import { useTranslation } from "react-i18next";

export const Contact = () => {
  const { t } = useTranslation();

  return (
    <section
      id="contact"
      className="flex justify-center bg-[#00BFFF] text-white"
    >
      <div className="max-w-[1200px] w-full py-16 px-5">
        <div className="flex justify-start">
          <div className="flex flex-col gap-8">
            <div className="flex items-center">
              <div className="avatar">
                <div className="w-24 sm:w-32 bg-[#00BFFF] rounded-full !flex items-center justify-center border-white border-4">
                  <img
                    className="!w-16 !h-16 !object-contain"
                    src="/antenne-white.png"
                    alt="antenne-white"
                  />
                </div>
              </div>
              <div>
                <h2 className="text-2xl md:text-5xl font-bold ml-6">
                  {t("contact.header")}
                </h2>
              </div>
            </div>
            <div>
              <p>{t("contact.underHeaded")}</p>
            </div>

            <p>TRAINCONSULT:</p>
            <div className="ml-6 flex gap-6 justify-end">
              <ol className="flex flex-col sm:flex-row justify-between w-full gap-6">
                <li>
                  <strong>EURAIL-ING</strong>
                  <ul>
                    <li>Reiner Konermann</li>
                    <li>Direkteur/ Managing Director</li>
                  </ul>
                </li>

                <li>
                  <ul>
                    <li>Kaldenkerkerweg 20</li>
                    <li>5913 AE Venlo</li>
                    <li>Telefon: +31 7700000 </li>
                    <li>info@trainconsult.nl</li>
                  </ul>
                </li>
              </ol>
            </div>
            <div className="flex justify-center">
              <div className="text-[#687888] font-bold text-center max-w-[1000px] px-5">
                <p className="mt-6">
                  Service, advice and consultancy for European railway companies
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
