import { useTranslation } from 'react-i18next';

export const Maintenance = () => {
  const { t } = useTranslation();

  return (
    <section id="maintenance-management" className="flex justify-center">
      <div className="max-w-[1200px] w-full py-16 px-5">
        <div className="flex justify-start">
          <div className="flex flex-col gap-8">
            <div className="flex items-center">
              <div className="avatar">
                <div className="w-24 sm:w-32 bg-[#00BFFF] rounded-full !flex items-center justify-center">
                  <img
                    className="!w-16 !h-16 !object-contain"
                    src="/train-white.png"
                    alt="train-white"
                  />
                </div>
              </div>
              <div>
                <h2 className="text-2xl block md:hidden font-bold ml-6">
                  {t('maintenance.header.headerWhithStroke')}
                </h2>
                <h2 className="text-5xl hidden md:block font-bold ml-6">
                  {t('maintenance.header.header')}
                </h2>
                <p className="text-1xl md:text-2xl font-medium ml-6">
                  {t('maintenance.header.ordinance')}
                </p>
              </div>
            </div>
            <div>
              <p>{t('maintenance.underHeaded')}</p>
            </div>

            <div className="block md:hidden">
              <img
                className="w-full max-h-[400px] object-cover rounded-xl shadow-3d"
                src="/railway.jpg"
                alt="railway"
              />
            </div>

            <p>{t('maintenance.services.header')}</p>
            <div className="ml-6 flex gap-6 flex-row-reverse">
              <ol>
                <li>
                  <strong className="block md:hidden ">
                    {t('maintenance.services.list1.headerWhithStroke')}
                  </strong>
                  <strong className="hidden md:block">
                    {t('maintenance.services.list1.header')}
                  </strong>
                  <ul>
                    <li className="block md:hidden ">
                      {t('maintenance.services.list1.point1')}
                    </li>
                    <li className="hidden md:block">
                      {t('maintenance.services.list1.point2')}
                    </li>
                    <li>{t('maintenance.services.list1.point3')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('maintenance.services.list2.header')}</strong>
                  <ul>
                    <li>{t('maintenance.services.list2.point1')}</li>
                    <li>{t('maintenance.services.list2.point2')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('maintenance.services.list3.header')}</strong>
                  <ul>
                    <li>{t('maintenance.services.list3.point1')}</li>
                    <li>{t('maintenance.services.list3.point2')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('maintenance.services.list4.header')}</strong>
                  <ul>
                    <li>{t('maintenance.services.list4.point1')}</li>
                    <li>{t('maintenance.services.list4.point2')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('maintenance.services.list5.header')}</strong>
                  <ul>
                    <li>{t('maintenance.services.list5.point1')}</li>
                    <li>{t('maintenance.services.list5.point2')}</li>
                  </ul>
                </li>
              </ol>

              <div className="hidden md:block">
                <img
                  className="w-[600px] h-full object-cover rounded-xl shadow-3d"
                  src="/railway.jpg"
                  alt="railway"
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="text-[#687888] font-bold text-center max-w-[1000px] py-8 px-5">
                <u>{t('maintenance.footer.header')}</u>
                <p className="mt-6">{t('maintenance.footer.point1')}</p>
                <p>{t('maintenance.footer.point2')}</p>
                <p>{t('maintenance.footer.point3')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
