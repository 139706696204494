import { useTranslation } from 'react-i18next';

export const PageLinks = () => {
  const { t } = useTranslation();

  return (
    <section id="about" className="hidden justify-center bg-[#00BFFF] md:flex">
      <div className="max-w-[1200px] w-full flex justify-center py-16 px-5 gap-14">
        <a
          href="#safety-management"
          className="flex flex-col w-48 items-center cursor-pointer hover:scale-105 transition-all"
        >
          <img
            className="w-20 h-20 object-contain"
            loading="lazy"
            src="/genie-civil-white.png"
            alt="genie-civil-white"
            typeof="foaf:Image"
          />
          <div className="text-white text-center font-bold ">
            <p> {t('links.point1')}</p>
          </div>
        </a>
        <a
          href="#maintenance-management"
          className="flex flex-col w-48 items-center cursor-pointer hover:scale-105 transition-all"
        >
          <img
            className="w-20 h-20 object-contain"
            loading="lazy"
            src="/train-white.png"
            alt="train-white"
            typeof="foaf:Image"
          />
          <div className="text-white text-center font-bold">
            <p>{t('links.point2')}</p>
          </div>
        </a>
        <a
          href="#approach"
          className="flex flex-col w-48 items-center cursor-pointer hover:scale-105 transition-all"
        >
          <img
            className="w-20 h-20 object-contain"
            loading="lazy"
            src="/signalisation-white.png"
            alt="signalisation-white"
            typeof="foaf:Image"
          />
          <div className="text-white text-center font-bold">
            <p>{t('links.point3')}</p>
          </div>
        </a>
        <a
          href="#contact"
          className="flex flex-col w-48 items-center cursor-pointer hover:scale-105 transition-all"
        >
          <img
            className="w-20 h-20 object-contain"
            loading="lazy"
            src="/antenne-white.png"
            alt="antenne-white"
            typeof="foaf:Image"
          />
          <div className="text-white text-center font-bold">
            <p>{t('links.point4')}</p>
          </div>
        </a>
      </div>
    </section>
  );
};
