import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationDE from './locales/de/translation.json';
import translationNL from './locales/nl/translation.json';

const determineLanguage = () => {
  const browserLang = navigator.language || navigator.userLanguage;

  // Sprache auf die Hauptsprache kürzen (z.B. "de-DE" zu "de")
  const lang = browserLang.split('-')[0];

  // Logik für die Standardsprache
  if (lang === 'de') {
    return 'de';
  } else if (lang === 'nl') {
    return 'nl';
  }
  return 'en';
};

i18n.use(initReactI18next).init({
  resources: {
    en: {
      translation: translationEN,
    },
    de: {
      translation: translationDE,
    },
    nl: {
      translation: translationNL,
    },
  },
  lng: determineLanguage(),
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
