import i18n from '../i18n';
import { useEffect, useRef, useState } from 'react';

export const Header = () => {
  const [activeLanguage, setActiveLanguage] = useState(i18n.language);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null); // Typ als HTMLDivElement festlegen

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const target = event.target as Node; // Event-Target als Node typisieren
      // Überprüfen, ob dropdownRef definiert ist und der Zielklick außerhalb des Dropdowns war
      if (dropdownRef.current && !dropdownRef.current.contains(target)) {
        setIsDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang);
    setActiveLanguage(lang);
    setIsDropdownOpen(false); // Schließt das Dropdown nach der Sprachauswahl
  };

  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  return (
    <div className="flex justify-center sticky top-0 bg-white/95 z-50">
      <header className="max-w-[1200px] w-full text-3xl font-semibold flex justify-between items-center p-2">
        <a href="/">
          <img
            className="h-[60px] hidden sm:block"
            loading="lazy"
            src="/LOGO TC complet.jpeg"
            alt="Logo"
            typeof="foaf:Image"
          />
          <img
            className="h-[60px] block sm:hidden"
            loading="lazy"
            src="/LOGO TC short.jpeg"
            alt="Logo"
            typeof="foaf:Image"
          />
        </a>
        <h1 className="text-[#00BFFF] drop-shadow-lg">Trainconsult</h1>

        <div className="w-[192px] hidden sm:flex justify-end">
          <div role="tablist" className="tabs tabs-boxed w-fit">
            <button
              role="tab"
              className={`tab ${
                activeLanguage === 'de'
                  ? 'tab-active !bg-[#00BFFF] !text-white'
                  : ''
              }`}
              onClick={() => changeLanguage('de')}
            >
              De
            </button>
            <button
              role="tab"
              className={`tab ${
                activeLanguage === 'en'
                  ? 'tab-active !bg-[#00BFFF] !text-white'
                  : ''
              }`}
              onClick={() => changeLanguage('en')}
            >
              En
            </button>
            <button
              role="tab"
              className={`tab ${
                activeLanguage === 'nl'
                  ? 'tab-active !bg-[#00BFFF] !text-white'
                  : ''
              } `}
              onClick={() => changeLanguage('nl')}
            >
              Nl
            </button>
          </div>
        </div>

        <div
          className="dropdown dropdown-end block sm:hidden"
          ref={dropdownRef}
        >
          <button
            className="btn btn-circle swap swap-rotate bg-[#00BFFF] hover:bg-[#009BDF]"
            onClick={toggleDropdown}
          >
            {/* Hamburger icon */}
            {!isDropdownOpen && (
              <svg
                className={`fill-white`}
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 512 512"
              >
                <path d="M64,384H448V341.33H64Zm0-106.67H448V234.67H64ZM64,128v42.67H448V128Z" />
              </svg>
            )}

            {/* Close icon */}
            {isDropdownOpen && (
              <svg
                className="fill-white"
                xmlns="http://www.w3.org/2000/svg"
                width="32"
                height="32"
                viewBox="0 0 512 512"
              >
                <polygon points="400 145.49 366.51 112 256 222.51 145.49 112 112 145.49 222.51 256 112 366.51 145.49 400 256 289.49 366.51 400 400 366.51 289.49 256 400 145.49" />
              </svg>
            )}
          </button>

          {isDropdownOpen && (
            <ul className="dropdown-content menu bg-base-100 rounded-box z-[1] w-36 p-2 shadow-xl">
              <li>
                <button
                  className={`w-full text-left ${
                    activeLanguage === 'de' ? 'bg-[#00BFFF] text-white' : ''
                  } p-2 rounded hover:bg-[#009BDF] transition-colors`}
                  onClick={() => changeLanguage('de')}
                >
                  Deutsch
                </button>
              </li>
              <li>
                <button
                  className={`w-full text-left ${
                    activeLanguage === 'en' ? 'bg-[#00BFFF] text-white' : ''
                  } p-2 rounded hover:bg-[#009BDF] transition-colors`}
                  onClick={() => changeLanguage('en')}
                >
                  English
                </button>
              </li>
              <li>
                <button
                  className={`w-full text-left ${
                    activeLanguage === 'nl' ? 'bg-[#00BFFF] text-white' : ''
                  } p-2 rounded hover:bg-[#009BDF] transition-colors`}
                  onClick={() => changeLanguage('nl')}
                >
                  Nederlands
                </button>
              </li>
            </ul>
          )}
        </div>
      </header>
    </div>
  );
};
