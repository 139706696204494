import { AboutUs } from './components/about-us';
import { Approach } from './components/approach';
import { Banner } from './components/banner';
import { Contact } from './components/contact';
import { Footer } from './components/footer';
import { Header } from './components/header';
import { Maintenance } from './components/maintenance';
import { PageLinks } from './components/page-links';
import { Safety } from './components/safety';

function App() {
  return (
    <>
      <Header />
      <Banner />
      <div className="flex justify-center flex-col">
        <AboutUs />

        <PageLinks />

        <Safety />

        <Maintenance />

        <Approach />

        <Contact />

        <Footer />
      </div>
    </>
  );
}

export default App;
