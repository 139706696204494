import { useTranslation } from 'react-i18next';

export const Banner = () => {
  const { t } = useTranslation();

  return (
    <div className="relative">
      <img
        className="w-full h-[400px] object-cover"
        loading="lazy"
        src="/outdoors-train.jpg"
        alt="Header Logo"
        typeof="foaf:Image"
      />
      <div className="absolute w-full bottom-1/4 md:bottom-5 text-center text-white uppercase">
        <p className="text-5xl font-bold">Be Safe</p>
        <p className="text-2xl font-extrabold">{t('banner')}</p>
      </div>
    </div>
  );
};
