import { useTranslation } from 'react-i18next';

export const Approach = () => {
  const { t } = useTranslation();

  return (
    <section id="approach" className="flex justify-center">
      <div className="max-w-[1200px] w-full py-16 px-5">
        <div className="flex justify-start">
          <div className="flex flex-col gap-8">
            <div className="flex items-center">
              <div className="avatar">
                <div className="w-24 sm:w-32 bg-[#00BFFF] rounded-full !flex items-center justify-center">
                  <img
                    className="!w-16 !h-16 !object-contain"
                    src="/signalisation-white.png"
                    alt="signalisation-white"
                  />
                </div>
              </div>
              <div>
                <h2 className="text-2xl md:text-5xl font-bold ml-6">
                  {t('approach.header.header')}
                </h2>
              </div>
            </div>
            <div>
              <p>{t('approach.underHeaded')}</p>
            </div>

            <div className="block md:hidden">
              <img
                className="w-full max-h-[400px] object-cover rounded-xl shadow-3d"
                src="/architecture.jpg"
                alt="architecture"
              />
            </div>

            <p>{t('approach.services.header')}</p>
            <div className="ml-6 flex gap-6">
              <ol>
                <li>
                  <strong>{t('approach.services.list1.header')}</strong>
                  <ul>
                    <li>{t('approach.services.list1.point1')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('approach.services.list2.header')}</strong>
                  <ul>
                    <li>{t('approach.services.list2.point1')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('approach.services.list3.header')}</strong>
                  <ul>
                    <li>{t('approach.services.list3.point1')}</li>
                  </ul>
                </li>
                <li>
                  <strong>{t('approach.services.list4.header')}</strong>
                  <ul>
                    <li>{t('approach.services.list4.point1')}</li>
                  </ul>
                </li>
              </ol>

              <div className="hidden md:block">
                <img
                  className="w-[600px] h-full object-cover rounded-xl shadow-3d"
                  src="/architecture.jpg"
                  alt="architecture"
                />
              </div>
            </div>
            <div className="flex justify-center">
              <div className="text-[#687888] font-bold text-center max-w-[1000px] py-8 px-5">
                <u>{t('approach.footer.header')}</u>
                <p className="mt-6">{t('approach.footer.point1')}</p>
                <p>{t('approach.footer.point2')}</p>
                <p className="mt-4">{t('approach.footer.point3')}</p>
                <p>{t('approach.footer.point4')}</p>
                <p className="mt-4">{t('approach.footer.point5')}</p>
                <p>{t('approach.footer.point6')}</p>
                <p className="mt-4">{t('approach.footer.point7')}</p>
                <p>{t('approach.footer.point8')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};
